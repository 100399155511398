<template>
	<div class="p-grid" >
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Vendedor')}}</h3>
        </div>	
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Button :disabled ="$store.state.loading" :label="$t('Nuevo')" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
					<template v-slot:right>
					</template>
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" :header="$t('Nombre')" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="email" :header="$t('Correo')" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.email}}
						</template>
					</Column>
                    <Column :header="$t('Estatus')" headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success"  v-if="slotProps.data.activo">{{$t('Activo')}}</span>
							<span class="p-tag p-tag-danger"  v-else>{{$t('No Activo')}}</span>
						</template>
					</Column>
					<Column :header="$t('Opciones')" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Vendedor')" :modal="true" class="p-fluid">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label for="name">{{ $t('Nombre') }}</label>
								<InputText id="name" v-model.trim="product.name" required="true"/>
							</div>
							<div class="p-col-10">
								<label for="nombre">{{ $t('Correo') }}</label>
								<InputText id="nombre" v-model.trim="product.email" required="true"/>
							</div>
                            <div class="p-col-2">
								<h6 style="margin-top: 0">{{$t('Estatus')}}</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-12">
								<DataTable :value="product.detail" v-model:expandedRows="expandedRows" dataKey="id"
									@rowExpand="onRowExpand" @rowCollapse="onRowCollapse" responsiveLayout="scroll">
									<Column :expander="true" headerStyle="width: 3rem" />					
									<Column field="nombre" :header="$t('Nombre')" :sortable="true">
										<template #body="slotProps">
											<div v-if="i18n.locale() == 'es'">
												{{slotProps.data.nombre}}
											</div>
											<div v-else>
												{{slotProps.data.name}}
											</div>
										</template>
									</Column>
									<template #expansion="slotProps">
										<div class="orders-subtable">
											<DataTable :value="slotProps.data.opciones" responsiveLayout="scroll">
												<Column field="tipocliente" :header="$t('Dirigido a')">
													<template #body="slotProps">
														<div v-if="i18n.locale() == 'es'">{{slotProps.data.nombre}}</div>
														<div v-if="i18n.locale() == 'en'">{{slotProps.data.name}}</div>
													</template>
												</Column>
												<Column field="limite" :header="$t('Factor')">
													<template #body="slotProps">
														<InputText v-model="slotProps.data.porcentaje" type="number" min="0" style="max-width:100px;"/>
													</template>
												</Column>
												<Column field="limite" :header="$t('Renovación')">
													<template #body="slotProps">
														<InputText v-model="slotProps.data.porcentaje2" type="number" min="0" style="max-width:100px;"/>
													</template>
												</Column>
											</DataTable>
										</div>
									</template>
								</DataTable>
							</div>		
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirm" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button :disabled ="$store.state.loading" label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Seller');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				products2: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				selectedProducts: null,
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '40vw',
				predeterminada: null,
				valores: [],
				niveles: null,
				expandedRows: [] 


			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.permisos = null;
				this.products = null;
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult;
				}); 
			},
			openNew() {
				this.product = {
					nombre: null,
					name: null,
				};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if(this.product.email && this.product.name){
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							product: this.product
						}).then(response => {
                            //this.$store.state.error = response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							product: this.product
						}).then(response => {
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.product = {...product};
				Consulta.Procesar('VER',{
					product: product
				}).then(response => {
					if (response.result) {
						this.product.detail = response.result;
						this.productDialog = true;
					} 
				});
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.Mostrar();
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
