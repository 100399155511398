<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Documentos')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
					:paginator="true" :rows="25" >
                    <template #header>
                        <div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
                            <div>{{ $t('Registros') }}: {{ products.length }}</div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column field="venc" :header="$t('Estatus')" headerStyle="width: 50px">
                        <template #body="slotProps">
                            <strong style="color:red" v-if="slotProps.data.venc<0">
								{{$t('Vencida')}}
							</strong>
							<strong  style="color:green" v-else>
								{{$t('Vigente')}}
							</strong>                        
                        </template>
                    </Column>
					<Column field="id" :header="$t('Tipo')" headerStyle="width: 50px">
                        <template #body="slotProps">
                            {{slotProps.data[i18n.locale()]}}                              
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Póliza')+' #'" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                        
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre')+'/'+$t('Correo')" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}<br>
                            {{slotProps.data.correo}}                          
                        </template>
                    </Column>
                    <Column field="emision" :header="$t('Emisión')" headerStyle="width: 50px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.emision)}}
                        </template>
                    </Column>
                    <Column field="caducidad" :header="$t('Vencimiento')" headerStyle="width: 50px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.caducidad)}}
                        </template>
                    </Column>
                    <Column field="venc" :header="$t('Nº Días Vigentes')" headerStyle="width: 50px">
                        <template #body="slotProps">
                            <strong style="color:red" v-if="slotProps.data.venc<0">
								{{slotProps.data.venc*(-1)}}
							</strong>
							<strong  style="color:green" v-else>
								{{slotProps.data.venc}}
							</strong>     
                        </template>
                    </Column> 
                    <Column :header="$t('Acciones')" :sortable="false" headerStyle="width: 50px">
                        <template #body="slotProps">
                            <Button :disabled ="$store.state.loading || slotProps.data.correo.includes('sabeinsurance.com')" icon="pi pi-envelope" class="p-button-rounded p-button-secondary p-mr-2"
                             @click="Send(slotProps.data)" v-if="slotProps.data.notif==null"/>
							{{slotProps.data.notif}}
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>

	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reportes','Report17');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				year: null,
				mes: null,
				nuevo: false,
				buscar: null,
				fecha1: null,
				fecha2: null,
				opcion: {code: 0, nombre: 'Todos', name: 'All'},
				opciones: [
                    {code: 0, nombre: 'Todos', name: 'All'},
                    {code: 1, nombre: 'Vigente', name: 'Current'},
                    {code: 2, nombre: 'Vencida', name: 'Expired'}
                ],
				insert: false,
				edit: false,
				del: false,
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				seleccion: {},
				validationErrors: {},
				products: [],
				pdf: null,
				lpdf: null,
			}
		},
		created() {
			this.year = this.$store.state.year;
			this.mes = this.$store.state.mes;
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
				}); 
			},
			Buscar(){
				if(this.mes && this.year && this.opcion){
					this.products = [];
					Consulta.Procesar('Buscar',{
						opcion: this.opcion.code,
						mes: this.mes.code,
						year: this.year.code,
						}).then(response => {
							//this.$store.state.error = response;
							this.products = response.result;
						});
				}
			},
            Send(datos){
				Consulta.Procesar('Send',{
					datos: datos,
					}).then(response => {
						//this.$store.state.error = response;
                        if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.displayeditar = false;
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('No fue procesada la solicitud'), 
								life: 3000
							});
							this.displayeditar = false;
						}          
                    });
            },
			VerXLS(){
				Consulta.Procesar('Ver',{
					opcion: this.opcion.code,
					}).then(response => {
						const xls = new XLS();
						xls.Reporte3(response.result.claims,this.$store.state.currency,this.reserva,this.totalbenf1,this.totalbenf2);
					});
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
